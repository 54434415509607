<template>
  <div>
    <!-- Table Families Card -->
    <b-card no-body class="mb-0">
      <div class="app-action">
        <b-row>
            <b-col
            cols="6"
            md="6"
            class="
              d-flex

              mb-1 mb-md-0
            ">
            <label>Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              v-model="perPage"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
              <!-- <label>SortBy</label>
              <v-select
              style="width:200px"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sortOptions"
                v-model="sort"
                multiple
                :clearable="true"
                 />
           -->
          </b-col>
          <b-col  cols="6" class="d-flex align-items-end justify-content-end mb-1">
            <b-button
            variant="outline-primary"
            @click="selectAllRows"
          >
           اختيار الكل
        </b-button>
        </b-col>
    </b-row>
        <div
          v-show="selectedItem.length"
          class="align-items-center"
          :class="{ 'd-flex': selectedItem.length }"
        >
          <template>
            <b-button variant="outline-primary" @click="responseAll(selectedItem, 1)">
              <span>موافقة</span>
            </b-button>
          </template>

          <b-button  variant="outline-primary" @click="responseAll(selectedItem, 0)">
            <span>رفض</span>
          </b-button>
        </div>
      </div>

      <!-- Update Mail Folder Dropdown -->

      <b-table
        ref="refFamiliesListTable"
        :items="fetchModificatio"

        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        select-mode="multi"
        selectable

        @row-selected="onRowSelected"

        :sort-desc.sync="isSortDirDesc"
        :filter="search"
        small
      >
        <template #cell(person_name)="data">
          <div class="text-nowrap">
            <feather-icon icon="UserIcon" size="18" class="mr-50 text-info" />
            <span class="align-text-top text-capitalize">{{
              data.item.person_name
            }}</span>
          </div>
        </template>
        <!-- Column: editfield -->
        <template #cell(field_name)="data">
          <div class="text-nowrap">
            <feather-icon icon="EditIcon" size="18" class="mr-50 text-info" />
            <span class="align-text-top text-capitalize">{{ data.item.field_name }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(action_id)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="sendResponse(data.item.action_id, 1)">
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">موافق</span>
            </b-dropdown-item>

            <b-dropdown-item @click="sendResponse(data.item.action_id, 0)">
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">رفض</span>
            </b-dropdown-item>
          </b-dropdown>

          <b-form-checkbox
            :checked="selectedItem.includes(data.item.action_id)"
            @change="toggleSelectedMail(data.item.action_id)"
            @click.native.stop
          />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted,computed } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";

import useFamiliesList from "./useModificationsList";
import familyStoreModule from "../familyStoreModule";
export default {
  setup() {
    const FAMILY_APP_STORE_MODULE_NAME = "app-family";

    // Register module
    if (!store.hasModule(FAMILY_APP_STORE_MODULE_NAME))
      store.registerModule(FAMILY_APP_STORE_MODULE_NAME, familyStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FAMILY_APP_STORE_MODULE_NAME))
        store.unregisterModule(FAMILY_APP_STORE_MODULE_NAME);
    });
       // Mail Selection
    // ------------------------------------------------

     const toggleSelectedMail = mailId => {
      const mailIndex = selectedItem.value.indexOf(mailId)

      if (mailIndex === -1) selectedItem.value.push(mailId)
      else selectedItem.value.splice(mailIndex, 1)

    }
     const selectAllItemCheckbox = computed(() => tableColumns.length && (tableColumns.length === selectedItem.value.length))
    const isSelectAllItemCheckboxIndeterminate = computed(() => Boolean(selectedItem.value.length) && tableColumns.length !== selectedItem.value.length)
  const selectAllCheckboxUpdate = val => {

      selectedItem.value = val ? tableColumns.map(mail => mail.id) : []

    }
    const onRowSelected = (items) => {
        //console.log(items)
        items.forEach(el=>{
            selectedItem.value.push(el.action_id)
        })


    };
    const selectAllRows=()=> {
      if(!selectedItem.value.length>0){
//
refFamiliesListTable.value.clearSelected()
      refFamiliesListTable.value.selectAllRows()
      }
      else{
        refFamiliesListTable.value.clearSelected()
      }
    }

    const {
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      fetchModificatio,
sendResponse,
responseAll,
selectedItem
      // Extra Filters
    } = useFamiliesList();

    return {
      // Sidebar
      toggleSelectedMail,
      search: null,
      fetchModificatio,
      selectAllRows,
      onRowSelected,
sendResponse,
responseAll,
      filterOn: [],
      searchTerm: "",
      tableColumns,
      selectedItem,

      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refFamiliesListTable,
      refetchData,

      // Filter
      avatarText,
      isSelectAllItemCheckboxIndeterminate,
      selectAllItemCheckbox,
      selectAllCheckboxUpdate
    };
  },

  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    BFormCheckbox
  },
  methods:{
    approveAll(){
      //console.log(this.selectedItem)
    }
  }
};
</script>

<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.app-action {
  margin: 18px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
